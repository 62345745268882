<template>
  <!--
    The view for the IntroOutroList-component.
    Used to display the component that lists the themes IntroOutros.
  -->
  <Portlet 
    :title="$t('menu.introsOutros')" 
    class="introOutroElementOverview"
    icon="film"
  >
    <template slot="buttons">
      <button 
        class="btn btn-sm btn-primary float-right mt-2 mb-2" 
        @click="showSidebarAdd"
      >
        <font-awesome-icon
          class="mr-1"
          icon="plus"
        />
        <span>
          {{ $t('add') }}
        </span>
      </button>
      <div class="clearfix" />
    </template>
    <IntroOutroList
      ref="introOutroList" 
      @reloadAuditLogs="reloadAuditLogs"
    />
    <DatabaseAuditLogList
      ref="databaseAuditLogList"
      :is-intro-outro-element="true"
      @reload="reloadIntroOutroList"
    />
  </Portlet>
</template>

<script>
export default {
  name: "IntroOutroElementOverview",
  components: {
    IntroOutroList: () => import('@/components/VideoPostprocessing/IntroOutro/IntroOutroList.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
  },
  metaInfo () {
    return {
      title: this.$t('videoPostProcessing.title')
    }
  },
  methods: {
    showSidebarAdd () {
      this.$refs.introOutroList.showSidebarAdd();
    },
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    reloadIntroOutroList () {
      this.$refs.introOutroList.reloadIntroOutros();
    }
  }
}
</script>
